<div
  #slideTile
  class="slide-wrapper d-flex align-items-center justify-content-center w-100"
  [style.backgroundColor]="backgroundColor"
>
  <swiper
    #swiper
    [slidesPerView]="3"
    [spaceBetween]="50"
    [centeredSlides]="true"
    [loop]="true"
    [autoHeight]="true"
    [allowTouchMove]="false"
    direction="horizontal"
    [pagination]="{ type: 'fraction' }"
    [navigation]="true"
    [ngStyle]="{ color: 'black', 'max-width.px': slideTile.offsetWidth / 2 }"
  >
    <ng-template
      swiperSlide
      *ngFor="let slide of content.items; let i = index"
      class="align-items-center justify-content-center"
    >
      <a [routerLink]="content.items[i].link">
        <img
          *ngIf="content.items[i].slideImage"
          [src]="content.items[i].slideImage.src"
          [alt]="content.items[i].slideImage.alt"
          [title]="content.items[i].slideImage.alt"
          [ngStyle]="{ 'max-width.px': slideTile.offsetWidth / 6.5, 'max-height.px': slideTile.offsetHeight - 10 }"
          loading="lazy"
        />
      </a>
    </ng-template>
  </swiper>
</div>
