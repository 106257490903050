import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  constructor(private translateService: TranslateService) {}

  getImprintLink(): string {
    if (this.translateService.currentLang === 'de') {
      return '/kontakt/imprint-privacy';
    }

    return '/kontakt/imprint-privacy';
  }

  getContactLink(): string {
    if (this.translateService.currentLang === 'de') {
      return '/kontakt';
    }

    return '/contact';
  }
}
