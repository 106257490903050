<div class="cookie-window" *ngIf="showCookiePrivacyInfo">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <p class="text">
          Diese Website verwendet Cookies. Klicke auf „Ich stimme zu“, um Cookies zu akzeptieren und uns bei der
          Optimierung unserer Website zu unterstützen.
          <a class="text-white" routerLink="/datenschutz">Datenschutzerklärung</a>
        </p>
      </div>
      <div class="col-md-12 col-lg-4 mt-3 mt-lg-0 d-flex justify-content-around align-items-center">
        <button class="primary-button secondary-button" (click)="changeCookiePrivacy(false)">Ablehnen</button>
        <button class="primary-button" (click)="changeCookiePrivacy(true)">Ich stimme zu</button>
      </div>
    </div>
  </div>
</div>
