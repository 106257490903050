<div class="find-us-container" [style.backgroundColor]="backgroundColor" [style.color]="textColor">
  <img [src]="content.image.src" [alt]="content.image.alt" loading="lazy" />
  <div class="text-card">
    <p class="title">{{ 'findUs.title' | translate }}</p>
    <div class="company">{{ content.company }}</div>
    <div class="text">
      {{ content.street }} {{ content.number }}<br />
      {{ content.postcode }} {{ content.city }}
    </div>
  </div>
</div>
