import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@lenne.tech/ng-base/shared';
import { Router } from '@angular/router';
import { PageService } from '../../services/page.service';
import { Page } from '../../models/page.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements AfterViewInit {
  collapse = false;
  currentLang = '';
  pagesLeft: Page[] = [];
  pagesRight: Page[] = [];
  activePageDropdown: string | null = null;
  activeSubPageDropdown: string | null = null;
  mobile: boolean = false;

  private unsubscribeScroll!: () => void;

  constructor(
    private translateService: TranslateService,
    private storageService: StorageService,
    private router: Router,
    private pageService: PageService,
    private renderer: Renderer2
  ) {
    if (window.screen.width < 576) {
      this.mobile = true;
    }
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLang = lang.lang;
    });

    this.pageService.pagesAsObservable.subscribe((pages) => {
      if (pages.length > 0) {
        const filteredPages = pages.filter((page) => page.mainMenu);

        const halfwayThrough = Math.floor(filteredPages.length / 2);
        this.pagesLeft = filteredPages.slice(0, halfwayThrough);
        this.pagesRight = filteredPages.slice(halfwayThrough, filteredPages.length);
      }
    });

    this.unsubscribeScroll = this.renderer.listen('document', 'scroll', () => {
      if (this.mobile == false) {
        this.activePageDropdown = null;
      }
    });
  }

  areChildrenOpen(page: Page) {
    if (!page?.childrenIds) {
      return false;
    }
    // On mobile the headers need to be opened with a click.
    // This makes navigation confusiing, so we need a way to automatically determin what navigation should be open
    // In this case we open it, if the website is open or it is reported as a parent by an open sub-page.
    if ((page.id === this.activePageDropdown || page.id === this.activeSubPageDropdown) && this.mobile) {
      return true;
    }

    if (this.mobile == false) {
      return page.childrenIds.includes(this.activePageDropdown);
    } else {
      return page.childrenIds.includes(this.activePageDropdown || this.activeSubPageDropdown);
    }
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe(() => {
      let activePage = this.pageService.getPageByRoute(this.router.url);
      if (!activePage?.childrenIds || activePage.childrenIds.length === 0) {
        this.activePageDropdown = null;
        this.setActiveSubPage(null);
        if (activePage) {
          let activeSubPageParent = this.pageService.getPageParent(activePage.id);
          if (activeSubPageParent) {
            this.setActiveSubPage(activeSubPageParent.id);
          }
        }
        return;
      }

      this.setActiveSubPage(activePage.id);
      let activePageParent = this.pageService.getPageParent(activePage.id);
      if (!activePageParent) {
        this.setActivePage(activePage.id);
        this.setActiveSubPage(null);
        return;
      }
      this.setActiveSubPage(activePage.id);
      this.setActivePage(activePageParent.id);
    });
  }

  hasChildrenAndAnyExist(page: Page): boolean {
    if (!page.childrenIds || page.childrenIds.length === 0) {
      return false;
    }
    let result: boolean = false;
    page.childrenIds.forEach((childrenPageId) => {
      if (this.getPageById(childrenPageId)) {
        result = true;
        return;
      }
    });

    return result;
  }

  getPageById(id: string) {
    return this.pageService.getPageById(id);
  }

  setActivePage(id: string) {
    this.activePageDropdown = id;
  }

  setActiveSubPage(id: string) {
    this.activeSubPageDropdown = id;
  }

  async changeLang(lang: 'de' | 'en') {
    this.translateService.use(lang);
    this.storageService.save('lang', lang);
  }

  isActiveSubPage(childID) {
    return this.activeSubPageDropdown === childID;
  }

  isLinkActive(url): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === '/' + url;
  }

  closeMenuOnNavigate() {
    if (this.collapse) {
      this.collapse = !this.collapse;
    }
  }
}
