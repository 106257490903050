import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { CardData } from '../../interfaces/cardData.interface';
import { TileType } from '../../models/tile.model';

@Component({
  selector: 'app-tile-wrapper',
  templateUrl: './tile-wrapper.component.html',
  styleUrls: ['./tile-wrapper.component.scss'],
})
export class TileWrapperComponent implements OnInit {
  @Input() content: TileType[] = [];
  screenHeight: number;
  screenWidth: number;

  ngOnInit() {
    // Change order of tiles, if it's a mobile device, because the tiles appear underneath each other, instead of next to.
    // If we don't change it, it would be *image image* *text text* instead of *image text image text*
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth <= 991) {
      var tempSwitchTile: TileType;
      for (var i: number = 0; i < this.content.length; i++) {
        this.content.forEach((tile) => {
          if (tile.mobilePositionIndex === i) {
            // Copy tile properties to our temporary tile object
            tempSwitchTile = new TileType(tile);
            this.content[this.content.indexOf(tile)] = new TileType(this.content[i]);
            this.content[i] = new TileType(tempSwitchTile);
          }
        });
      }
    }
  }
}
