<div class="page-tile-container" [ngClass]="'tile-col-' + cols">
  <ng-container *ngFor="let tile of content">
    <app-tile [backgroundImage]="tile.image" [isPageTile]="true" position="relative" transformContentToCenter="false">
      <a [routerLink]="tile?.button?.link">
        <button class="btn btn-tile">{{ tile.button.text }}</button>
      </a>
      <h2 class="page-tile-description">{{ tile.text }}</h2>
    </app-tile>
  </ng-container>
</div>
