import { Component, Input } from '@angular/core';
import { PageTileType } from '../../models/page-tile-type.model';

@Component({
  selector: 'app-page-tile',
  templateUrl: './page-tile.component.html',
  styleUrls: ['./page-tile.component.scss'],
})
export class PageTileComponent {
  @Input() cols = 4;
  @Input() content: PageTileType[] = [];
}
