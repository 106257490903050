<div class="wrapper">
  <div [ngClass]="content.invertBackground ? 'wrapper-background-2' : 'wrapper-background-1'"></div>
  <div [ngClass]="content.invertBackground ? 'wrapper-background-1' : 'wrapper-background-2'"></div>
  <div class="wrapper-content">
    <div class="row">
      <div class="col-12 col-md-6 wrapper-content-image" *ngIf="content.image?.src">
        <img [src]="content.image?.src" [alt]="content.image?.alt" loading="lazy" />
      </div>
      <div class="col-12 col-md-6 wrapper-content-text">
        <div>
          <div class="titleText" *ngIf="content?.title">{{ content.title }}</div>
          <div class="titleText" *ngIf="content?.subtitle">{{ content.subtitle }}</div>

          <div class="color-accent nameText" *ngIf="content?.name">{{ content.name }}</div>
          <div class="otherText" *ngIf="content?.role">{{ content.role }}</div>

          <div *ngIf="content?.phone">
            <a class="text-decoration-none otherText">{{ content.phone }}</a>
          </div>
          <div *ngIf="content?.email">
            <a class="text-decoration-none otherText">{{ content.email }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
