import { Component, ElementRef, ViewChild, AfterViewInit, HostListener, Input } from '@angular/core';
import { Image } from '../../interfaces/image.interface';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements AfterViewInit {
  @Input() title: { text: string; color: string } = undefined;
  @ViewChild('titleElement') titleElement!: ElementRef;
  @Input() image: Image;
  constructor() {}

  ngAfterViewInit() {
    this.adjustFontSize();
  }

  @HostListener('window:resize')
  onResize() {
    this.adjustFontSize();
  }

  private adjustFontSize() {
    const element = this.titleElement.nativeElement;

    // Reset to the original SCSS-defined font size
    element.style.fontSize = ''; // Clear inline styles to use the SCSS file

    const computedStyle = window.getComputedStyle(element);
    let fontSize = parseInt(computedStyle.fontSize, 10);

    // Reduce font size if necessary to make it fit
    while (element.scrollWidth > element.clientWidth && fontSize > 8) {
      fontSize--;
      element.style.fontSize = `${fontSize}px`;
    }
  }
}
