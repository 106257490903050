<div>
  <ng-container *ngFor="let text of content">
    <div
      [style.backgroundColor]="text.backgroundColor"
      class="d-flex flex-column justify-content-center align-items-center text-center"
    >
      <div *ngIf="text.headerLayout === 'h3p'">
        <h3 class="title mt-4" *ngIf="text.title !== null && text.title">{{ text.title }}</h3>
        <p class="subtitle mt-2">{{ text.subtitle }}</p>
      </div>
      <div *ngIf="text.headerLayout === 'ph2'">
        <p class="title mt-4" *ngIf="text.title !== null && text.title">{{ text.title }}</p>
        <h2 class="subtitle mt-2">{{ text.subtitle }}</h2>
      </div>
      <div *ngIf="text.headerLayout === 'h2h2' || !text.headerLayout">
        <h2 class="title mt-4" *ngIf="text.title !== null && text.title">{{ text.title }}</h2>
        <h2 class="subtitle mt-2">{{ text.subtitle }}</h2>
      </div>

      <p class="text">
        {{ text.text }}
        <a *ngIf="text.hyperlinkDownloadType" [href]="text.hyperlinkDownloadType.link" download>{{
          text.hyperlinkDownloadType.text
        }}</a>
      </p>
    </div>
  </ng-container>
</div>
