import { BrowserModule, TransferState } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './modules/core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { BaseModule } from '@lenne.tech/ng-base';
import { environment } from '../environments/environment';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './modules/core/loader/translate-browser.loader';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { AppInitService } from './modules/core/services/app-init.service';
import { SwiperModule } from 'swiper/angular';
import { GtagModule } from 'angular-gtag';

export function initializeApp(appInitService: AppInitService) {
  return () => appInitService.init();
}

@NgModule({
  declarations: [
    // Components
    AppComponent,
    // Pages
    MainComponent,
  ],
  imports: [
    // Angular
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BaseModule.forRoot(environment),
    BrowserAnimationsModule,
    TransferHttpCacheModule,
    HttpClientModule,
    SwiperModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    AppRoutingModule,

    FormsModule,
    ReactiveFormsModule,
    GtagModule.forRoot({ trackingId: 'AW-847539444', trackPageviews: true }),
    // Intern
    CoreModule,
  ],
  providers: [{ provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
