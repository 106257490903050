export enum TypesEnum {
  TEXT = 'TEXT',
  FILLER = 'FILLER',
  IMAGE = 'IMAGE',
  PAGE_TILE = 'PAGE_TILE',
  TILES = 'TILES',
  DROPDOWN_TILES = 'DROPDOWN_TILES',
  PANELS = 'PANELS',
  TEXTS = 'TEXTS',
  FIND_US = 'FIND_US',
  REFERENCE_SLIDER = 'REFERENCE_SLIDER',
  VIDEO = 'VIDEO',
  CONTACT = 'CONTACT',
  CONTACT_FOR_QUESTIONS = 'CONTACT_FOR_QUESTIONS',
  SOCIAL_MEDIA = 'SOCIAL_MEDIA',
  SLIDER = 'SLIDER',
  SPACER = 'SPACER',
}
