import { Helper, Standard } from '@lenne.tech/ng-base/shared';
import { Image } from '../interfaces/image.interface';
import { Button } from '../interfaces/button.interface';

export class TileType extends Standard {
  image?: Image = undefined;
  title: { text: string; color: string } = undefined;
  subtitle: { text: string; color: string } = undefined;
  titleNumber: { text: string; color: string } = undefined;
  description: { text: string; color: string } = undefined;
  button?: Button = undefined;
  backgroundColor?: string = undefined;
  backgroundImage?: Image = undefined;
  topPercent?: number = 50;
  leftPercent?: number = 50;
  imageMaxFractionOfHeight?: number = 5;
  textAlignment?: string = 'center';
  mobilePositionIndex?: number = 0;
  scaleDownImage = false;
  isFlippable = false;
  isPageTile = false;
  headerLayout: string = 'h2h2';

  constructor(tile: TileType) {
    super();
    this.image = tile.image;
    this.title = tile.title;
    this.subtitle = tile.subtitle;
    this.titleNumber = tile.titleNumber;
    this.description = tile.description;
    this.button = tile.button;
    this.backgroundColor = tile.backgroundColor;
    this.backgroundImage = tile.backgroundImage;
    this.topPercent = tile.topPercent;
    this.leftPercent = tile.leftPercent;
    this.imageMaxFractionOfHeight = tile.imageMaxFractionOfHeight;
    this.textAlignment = tile.textAlignment;
    this.mobilePositionIndex = tile.mobilePositionIndex;
    this.scaleDownImage = tile.scaleDownImage;
    this.isFlippable = tile.isFlippable;
    this.isPageTile = tile.isPageTile;
    this.headerLayout = tile.headerLayout;
  }

  public map(data: Partial<this> | { [key: string]: any }): this {
    Helper.map(data, this);
    return this;
  }
}
