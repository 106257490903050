<div class="panel-container">
  <div
    class="image-container mb-2"
    *ngIf="image.src"
    [ngStyle]="{
      'justify-content': image.src ? null : 'center',
      height: image.heightPercent ? image.heightPercent + '%' : '70%'
    }"
  >
    <div class="icon-spacer"></div>
    <img [src]="image.src" class="image mt-2" [alt]="image.alt" [title]="image.alt" loading="lazy" />
  </div>
  <h2 #titleElement class="title" [style.color]="title?.color">{{ title?.text }}</h2>
</div>
