<div [style.backgroundColor]="backgroundColor" [style.color]="textColor">
  <div
    #textDiv
    class="text-container"
    [ngStyle]="
      content.paddingTopBottom !== undefined
        ? { 'padding-top': content.paddingTopBottom + 'px', 'padding-bottom': content.paddingTopBottom + 'px' }
        : ''
    "
  >
    <div *ngIf="content.headerLayout === 'h3p'">
      <h3 class="title" [style.color]="textColor">{{ content.title }}</h3>
      <p class="subtitle">{{ content.subtitle }}</p>
    </div>
    <div *ngIf="content.headerLayout === 'ph2'">
      <p class="title" [style.color]="textColor">{{ content.title }}</p>
      <h2 class="subtitle">{{ content.subtitle }}</h2>
    </div>
    <div *ngIf="content.headerLayout === 'h2h2' || !content.headerLayout">
      <h2 class="title" [style.color]="textColor">{{ content.title }}</h2>
      <h2 class="subtitle">{{ content.subtitle }}</h2>
    </div>

    <p class="text">
      {{ content.text }}
      <a *ngIf="content.hyperlinkDownloadType" [href]="content.hyperlinkDownloadType.link" download>{{
        content.hyperlinkDownloadType.text
      }}</a>
    </p>
    <div class="d-flex justify-content-center align-items-center">
      <img
        *ngIf="content?.imageBottom"
        [src]="content.imageBottom?.src"
        [alt]="content.imageBottom?.alt"
        [title]="content.imageBottom?.alt"
        loading="lazy"
        [ngStyle]="{ 'height.px': textDiv.offsetWidth / 10 }"
      />
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <button *ngIf="content.button" class="btn btn-accent" [routerLink]="content.button.link">
        {{ content.button.text }}
      </button>
    </div>
  </div>
</div>
