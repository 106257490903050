import { Component, Input } from '@angular/core';
import { FindUsType } from '../../models/find-us-type.model';

@Component({
  selector: 'app-find-us',
  templateUrl: './find-us.component.html',
  styleUrls: ['./find-us.component.scss'],
})
export class FindUsComponent {
  @Input() content: FindUsType;
  @Input() backgroundColor = '#FFFFFF';
  @Input() textColor = 'black';
}
