import { Component, Input } from '@angular/core';
import { ContactForQuestionsType } from '../../models/contact-for-questions.model';

@Component({
  selector: 'app-contact-for-questions',
  templateUrl: './contact-for-questions.component.html',
  styleUrls: ['./contact-for-questions.component.scss'],
})
export class ContactForQuestionsComponent {
  @Input() content: ContactForQuestionsType;
}
