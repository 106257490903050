import { Helper, Standard } from '@lenne.tech/ng-base/shared';
import { TextType } from './text-type.model';
import { TypesEnum } from '../enums/types.enum';
import { ImageType } from './image-type.model';
import { PageTileType } from './page-tile-type.model';
import { ReferenceSliderType } from './reference-slider-type.model';
import { FindUsType } from './find-us-type.model';
import { TileType } from './tile.model';
import { VideoType } from './video';
import { ContactType } from './contact.model';
import { ContactForQuestionsType } from './contact-for-questions.model';
import { SocialMediaType } from './social-media-type.model';
import { SliderTypeModel } from './slider-type.model';
import { FillerType } from './filler-type.model';
import { PanelType } from './panel.model';

export class CoreType extends Standard {
  id: string = undefined;
  backgroundColor?: string = undefined;
  height?: number = undefined;
  textColor?: string = undefined;
  heightMultiplicator?: number = undefined;
  cols?: number = undefined;
  type: TypesEnum = undefined;
  textType?: TextType = undefined;
  imageType?: ImageType = undefined;
  pageTileType?: PageTileType[] = undefined;
  referenceSliderType?: ReferenceSliderType = undefined;
  tiles?: TileType[] = undefined;
  panels?: PanelType[] = undefined;
  opacityMultiplier?: number = 0.15;
  texts?: TextType[] = undefined;
  filler?: FillerType = undefined;
  findUsType?: FindUsType = undefined;
  videoType?: VideoType = undefined;
  contactType?: ContactType = undefined;
  contactForQuestionsType?: ContactForQuestionsType = undefined;
  socialMediaType?: SocialMediaType = undefined;
  sliderType?: SliderTypeModel = undefined;

  public map(data: Partial<this> | { [key: string]: any }): this {
    Helper.map(data, this);
    return this;
  }
}
