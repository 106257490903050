import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CookieInfoComponent } from './components/cookie-info/cookie-info.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateModule } from '@ngx-translate/core';
import { HeroImageComponent } from './components/hero-image/hero-image.component';
import { TextComponent } from './components/text/text.component';
import { ImageComponent } from './components/image/image.component';
import { TileComponent } from './components/tile/tile.component';
import { PageTileComponent } from './components/page-tile/page-tile.component';
import { FindUsComponent } from './components/find-us/find-us.component';
import { SideContactComponent } from './components/side-contact/side-contact.component';
import { ReferenceSliderComponent } from './components/reference-slider/reference-slider.component';
import { TileWrapperComponent } from './components/tile-wrapper/tile-wrapper.component';
import { DropdownTileWrapperComponent } from './components/dropdown-tile-wrapper/dropdown-tile-wrapper.component';
import { PanelWrapperComponent } from './components/panel-wrapper/panel-wrapper.component';
import { PanelComponent } from './components/panel/panel.component';
import { SmoothExpanderComponent } from './components/smooth-expander/smooth-expander.component';
import { TextWrapperComponent } from './components/text-wrapper/text-wrapper.component';
import { VideoComponent } from './components/video/video.component';
import { ContactComponent } from './components/contact/contact.component';
import { ContactForQuestionsComponent } from './components/contact-for-questions/contact-for-questions.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { SliderComponent } from './components/slider/slider.component';
import { SwiperModule } from 'swiper/angular';
import { FillerComponent } from './components/filler/filler.component';
import { CookieBannerComponent } from './components/cookie-banner/cookie-banner.component';
import { SpacerComponent } from './components/spacer/spacer.component';

@NgModule({
  imports: [
    // Modules
    RouterModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    SwiperModule,
  ],
  declarations: [
    // Components
    HeaderComponent,
    FooterComponent,
    CookieInfoComponent,
    HeroImageComponent,
    TextComponent,
    ImageComponent,
    TileComponent,
    PageTileComponent,
    FindUsComponent,
    SideContactComponent,
    TileWrapperComponent,
    DropdownTileWrapperComponent,
    PanelComponent,
    PanelWrapperComponent,
    SmoothExpanderComponent,
    ReferenceSliderComponent,
    VideoComponent,
    ContactComponent,
    ContactForQuestionsComponent,
    SocialMediaComponent,
    SliderComponent,
    TextWrapperComponent,
    FillerComponent,
    CookieBannerComponent,
    SpacerComponent,
  ],
  exports: [
    // Components
    HeaderComponent,
    FooterComponent,
    CookieInfoComponent,
    HeroImageComponent,
    TextComponent,
    ImageComponent,
    TileComponent,
    PageTileComponent,
    FindUsComponent,
    SideContactComponent,
    TileWrapperComponent,
    DropdownTileWrapperComponent,
    PanelComponent,
    PanelWrapperComponent,
    SmoothExpanderComponent,
    ReferenceSliderComponent,
    VideoComponent,
    ContactComponent,
    ContactForQuestionsComponent,
    SocialMediaComponent,
    SliderComponent,
    TextWrapperComponent,
    SwiperModule,
    FillerComponent,
    CookieBannerComponent,
    SpacerComponent,
  ],
})
export class CoreModule {}
