import { Injectable } from '@angular/core';
import de from '../../../data/pages/de.json';
import en from '../../../data/pages/en.json';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Page } from '../models/page.model';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  _pages: BehaviorSubject<Page[]> = new BehaviorSubject<Page[]>([]);

  get pages(): Page[] {
    return this._pages.value;
  }

  set pages(pages: Page[]) {
    this._pages.next(pages);
  }

  get pagesAsObservable(): Observable<Page[]> {
    return this._pages.asObservable();
  }

  constructor(private translateService: TranslateService) {
    this.loadPages(this.translateService.currentLang);
    this.loadPagesAfterLangChange();
  }

  loadPagesAfterLangChange() {
    this.translateService.onLangChange.subscribe((event) => {
      this.loadPages(event.lang);
    });
  }

  getPageById(id: string): Page | undefined {
    return this.pages.find((page) => page.id === id);
  }

  loadPages(lang: string) {
    this.pages = this.getPages(lang);
  }

  getPages(lang: string) {
    const pages = lang === 'de' ? de.pages : en.pages;
    const result: Page[] = [];
    pages.forEach((page) => {
      result.push(Page.map(page));
    });
    return result;
  }

  getPageByName(name: string) {
    // No valid string
    if (!name) {
      return undefined;
    }
    return this.pages.find((page) => page?.name?.toLowerCase() === name.toLowerCase());
  }

  getPageByRoute(route: string) {
    // No valid string
    if (!route) {
      return undefined;
    }
    // Sometimes the path has a '/' at the start, sometimes not. Lets just allow any of the comparisons
    return this.pages.find(
      (page) =>
        page?.route?.toLowerCase() === route.toLowerCase() ||
        page.route.toLowerCase().substring(1) === route.toLowerCase() ||
        page.route.toLowerCase() === route.toLowerCase().substring(1)
    );
  }

  getPageParent(childID: string) {
    return this.pages.find((page) => page?.childrenIds && page.childrenIds.includes(childID));
  }
}
