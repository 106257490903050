<div class="tile-wrapper">
  <ng-container *ngFor="let tile of content; index as i">
    <div
      class="tile-container-wrapper"
      #square
      (window:resize)="(0)"
      [ngStyle]="
        (i === 0 && { backgroundColor: tile.backgroundColor }) ||
        (i !== 0 && { backgroundColor: getBackgroundColorFromIndex(i) })
      "
      class="host"
    >
      <div class="tile-container" [ngStyle]="this.mobile && { width: '80%', transform: 'translateX(10%)' }">
        <div class="header-text-container" (click)="expandTile(i)" type="button">
          <div class="title-text-container">
            <h3 class="title-number me-3" [style.Color]="tile?.titleNumber?.color">{{ tile?.titleNumber?.text }}</h3>
            <h3 class="title" [style.Color]="tile?.title?.color">{{ tile?.title?.text }}</h3>
          </div>
          <button
            class="expand-button"
            type="button"
            title="Toggle expand"
            [ngStyle]="{
              transform:
                (expandedTileIndex === i ? 'rotateZ(180deg)' : 'rotateZ(0deg)') +
                ' ' +
                (this.mobile ? (expandedTileIndex === i ? 'translateY(4px)' : 'translateY(-4px)') : 'translateY(0px)')
            }"
          >
            V
          </button>
        </div>
        <div #textContainer class="tile-text-container mt-3">
          <app-smooth-expander [trigger]="expandedTileIndex === i">
            <div class="mb-3" *ngIf="expandedTileIndex === i">
              <p class="subtitle" [style.Color]="tile?.subtitle?.color" [ngStyle]="this.mobile && { width: '100%' }">
                {{ tile?.subtitle?.text }}
              </p>
              <div class="tile-text-content-container">
                <p
                  class="paragraph pre-line"
                  [style.Color]="tile?.description?.color"
                  [ngStyle]="this.mobile && { width: '100%', 'margin-bottom': '15px' }"
                >
                  {{ tile?.description?.text }}
                </p>

                <img
                  class="image ms-5"
                  *ngIf="tile.backgroundImage && expandedTileIndex === i && this.mobile === false"
                  [src]="tile.backgroundImage?.src"
                  [alt]="tile.backgroundImage?.alt"
                  [title]="tile.backgroundImage?.alt"
                  loading="lazy"
                />
              </div>
              <img
                class="image ms-5 mb-3"
                *ngIf="tile.backgroundImage && expandedTileIndex === i && this.mobile === true"
                [src]="tile.backgroundImage?.src"
                [alt]="tile.backgroundImage?.alt"
                [title]="tile.backgroundImage?.alt"
                loading="lazy"
              />

              <div class="text-center">
                <a [routerLink]="tile?.button?.link">
                  <button
                    *ngIf="tile?.button"
                    class="btn"
                    [ngClass]="tile?.button?.classes ? tile.button.classes : 'btn-accent'"
                  >
                    {{ tile?.button?.text }}
                  </button>
                </a>
              </div>
            </div>
          </app-smooth-expander>
        </div>
      </div>
    </div>
  </ng-container>
</div>
