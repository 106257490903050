<header class="header fixed-top">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">
      <div class="w-100 d-flex flex-row justify-content-between d-lg-none">
        <a class="navbar-brand" routerLink="/" (click)="closeMenuOnNavigate()">
          <img alt="Logo von localino" src="../../assets/images/logo.svg" loading="lazy" />
        </a>
      </div>

      <div #navBar class="collapse navbar-collapse" [ngClass]="{ show: collapse }">
        <ul class="navbar-nav justify-content-end">
          <li
            class="nav-item"
            *ngFor="let page of pagesLeft"
            (mouseenter)="setActivePage(page.id)"
            [class.activedropdown]="activePageDropdown === page.id || areChildrenOpen(page)"
          >
            <a
              *ngIf="page.route && !page.link"
              class="nav-link scroll-link"
              [routerLink]="page.route"
              [class.activedropdown]="isLinkActive(page.route)"
              (click)="closeMenuOnNavigate()"
              >{{ page.title }}
            </a>
            <a *ngIf="!page.route && page.link" class="nav-link scroll-link" [href]="page.link">{{ page.title }} </a>
            <div
              class="dropdown-menu"
              *ngIf="hasChildrenAndAnyExist(page)"
              [ngStyle]="mobile && { 'margin-left': '10px' }"
            >
              <div
                class="dropdown-item-container"
                *ngFor="let childrenId of page.childrenIds; let i = $index"
                [class.activedropdown]="isActiveSubPage(childrenId)"
              >
                <a
                  class="dropdown-item"
                  [class.activedropdown]="isLinkActive(getPageById(childrenId)?.route)"
                  (click)="closeMenuOnNavigate()"
                  [routerLink]="getPageById(childrenId)?.route"
                >
                  {{ getPageById(childrenId)?.title }}
                  <span *ngIf="hasChildrenAndAnyExist(getPageById(childrenId))">
                    <div class="dropdown-arrow ms-1">▼</div>
                  </span>
                </a>

                <div class="dropdown-subitem" *ngIf="hasChildrenAndAnyExist(getPageById(childrenId))">
                  <a
                    class="dropdown-item dropdown-subitem ms-2"
                    *ngFor="let childrenId of getPageById(childrenId).childrenIds"
                    [class.activedropdown]="isLinkActive(getPageById(childrenId)?.route)"
                    (click)="closeMenuOnNavigate()"
                    [routerLink]="getPageById(childrenId)?.route"
                    >{{ getPageById(childrenId)?.title }}</a
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
        <a class="navbar-brand d-none d-sm-flex" routerLink="/" *ngIf="!collapse">
          <img alt="Logo von localino" src="../../assets/images/logo.svg" loading="lazy" />
        </a>
        <ul class="navbar-nav">
          <li
            class="nav-item"
            *ngFor="let page of pagesRight"
            [class.activedropdown]="activePageDropdown === page.id || areChildrenOpen(page)"
          >
            <a
              *ngIf="page.route && !page.link"
              class="nav-link scroll-link"
              [routerLink]="page.route"
              [class.activedropdown]="isLinkActive(page.route)"
              (click)="closeMenuOnNavigate()"
              >{{ page.title }}</a
            >
            <a *ngIf="!page.route && page.link" class="nav-link scroll-link" [href]="page.link">{{ page.title }} </a>
            <div
              class="dropdown-menu"
              *ngIf="hasChildrenAndAnyExist(page)"
              [ngStyle]="mobile && { 'margin-left': '10px' }"
            >
              <div
                class="dropdown-item-container"
                *ngFor="let childrenId of page.childrenIds; let i = $index"
                [class.activedropdown]="isActiveSubPage(childrenId)"
              >
                <a
                  class="dropdown-item"
                  *ngFor="let childrenId of page.childrenIds"
                  [class.activedropdown]="isLinkActive(getPageById(childrenId)?.route)"
                  (click)="closeMenuOnNavigate()"
                  [routerLink]="getPageById(childrenId)?.route"
                >
                  <span *ngIf="hasChildrenAndAnyExist(getPageById(childrenId))">
                    <div class="dropdown-arrow ms-1">▼</div>
                  </span>
                </a>

                <div class="dropdown-subitem" *ngIf="hasChildrenAndAnyExist(getPageById(childrenId))">
                  <a
                    class="dropdown-item dropdown-subitem ms-2"
                    *ngFor="let childrenId of getPageById(childrenId).childrenIds"
                    [class.activedropdown]="isLinkActive(getPageById(childrenId)?.route)"
                    (click)="closeMenuOnNavigate()"
                    [routerLink]="getPageById(childrenId)?.route"
                    >{{ getPageById(childrenId)?.title }}</a
                  >
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="language-switcher-wrapper">
        <ul class="language-switcher" *ngIf="!collapse">
          <li class="me-2 language" (click)="changeLang('de')" [ngClass]="{ active: currentLang === 'de' }">DE</li>
          <li>|</li>
          <li class="ms-2 language" (click)="changeLang('en')" [ngClass]="{ active: currentLang === 'en' }">EN</li>
        </ul>

        <button
          type="button"
          class="nav-toogler ms-2 d-lg-none"
          title="button for mobile navigation"
          (click)="collapse = !collapse"
        >
          <i class="bi bi-x" *ngIf="collapse"></i>
          <i class="bi bi-list" *ngIf="!collapse"></i>
        </button>
      </div>
    </div>
  </nav>
</header>
