import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@lenne.tech/ng-base/shared';

@Injectable({ providedIn: 'root' })
export class AppInitService {
  translateService: TranslateService;
  storageService: StorageService;

  constructor(private injector: Injector) {}

  /**
   * Init app and set start language
   */
  async init() {
    return new Promise<void>(async (resolve, reject) => {
      // Inject services
      this.translateService = this.injector.get(TranslateService);
      this.storageService = this.injector.get(StorageService);

      // Check if language already set and saved in localstorage
      if (this.storageService.load('lang')) {
        await this.translateService.use(this.storageService.load('lang')).toPromise();

        // Check Browser language
      } else if (navigator && navigator?.language) {
        const browserLang = navigator?.language === 'de-DE' ? 'de' : 'en';
        await this.translateService.use(browserLang).toPromise();

        // Use default language
      } else {
        await this.translateService.use('en').toPromise();
      }

      // Init app if translation already loaded
      await this.translateService.get('init').toPromise();

      resolve();
    });
  }
}
