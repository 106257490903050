import { Component, Input, OnInit } from '@angular/core';
import { TextType } from '../../models/text-type.model';

@Component({
  selector: 'app-text-wrapper',
  templateUrl: './text-wrapper.component.html',
  styleUrls: ['./text-wrapper.component.scss'],
})
export class TextWrapperComponent implements OnInit {
  @Input() content: TextType[] = [];

  ngOnInit() {
    // The splitTexts array only exists so we can have a better overview in the design json files. Otherwise we have extremely long strings without line breaks.
    // Valid json files can't have a string split into multiple lines
    if (this.content) {
      this.content.forEach((textObject) => {
        if (textObject.splitTexts) {
          textObject.splitTexts.forEach((partOfTexts) => {
            textObject.text += partOfTexts;
          });
        }
      });
    }
  }
}
