import { Component, Input, OnInit } from '@angular/core';
@Component({
  selector: 'app-filler',
  templateUrl: './filler.component.html',
  styleUrls: ['./filler.component.scss'],
})
export class FillerComponent implements OnInit {
  @Input() backgroundColor: string;
  @Input() heightMultiplicator: number;

  ngOnInit(): void {
    console.log(this.heightMultiplicator);
    console.log(this.backgroundColor);
  }
}
