import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { ReferenceSliderType } from '../../models/reference-slider-type.model';
import Splide from '@splidejs/splide';
import { Image } from '../../interfaces/image.interface';
import { isPlatformBrowser } from '@angular/common';
import { debounce } from '../../decorators/debounce.decorator';

@Component({
  selector: 'app-reference-slider',
  templateUrl: './reference-slider.component.html',
  styleUrls: ['./reference-slider.component.scss'],
})
export class ReferenceSliderComponent implements AfterViewInit, OnInit {
  @Input() content: ReferenceSliderType;
  slider: Splide;
  activeSlide: Image;
  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) platformId: any) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.activeSlide = this.content.referenceImages[0];
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.initSplide();
      this.initSplideListeners();
    }
  }

  initSplide() {
    this.slider = new Splide('.splide', {
      type: 'loop',
      perPage: window.innerWidth < 1000 ? 1 : 3,
      focus: 'center',
      rewind: false,
      drag: false,
      autoplay: false,
      interval: 5000,
    });

    this.slider.mount();
  }

  @HostListener('window:resize', ['$event'])
  @debounce()
  onResize() {
    this.slider.destroy(true);
    this.initSplide();
    this.initSplideListeners();
  }

  initSplideListeners() {
    this.slider.on('moved', (i) => {
      this.activeSlide = this.content.referenceImages[i];
    });
  }
}
