import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss'],
})
export class CookieBannerComponent implements AfterViewInit {
  constructor(private translateService: TranslateService) {}
  @ViewChild('cookieBanner') cookieBanner: ElementRef<HTMLDivElement>;
  isAnalyticsChecked: boolean = true;
  isPreferencesChecked: boolean = true;
  isMarketingChecked: boolean = true;
  headerText: string = '';
  cookieDescriptionText: string = '';
  acceptText: string = '';
  acceptSelectionText: string = '';
  rejectText: string = '';
  necessaryCheckboxText: string = '';
  analyticsCheckboxText: string = '';
  prefrencesCheckboxText: string = '';
  marketingCheckboxText: string = '';

  ngAfterViewInit(): void {
    if (
      localStorage.getItem('consentDate') !== null &&
      new Date(localStorage.getItem('consentDate')).getDate() < new Date().getDate() - 30
    ) {
      localStorage.setItem('consentMode', null);
      localStorage.setItem('consentDate', null);
    }

    if (localStorage.getItem('consentMode') === null) {
      this.cookieBanner.nativeElement.style.display = 'block';
    } else {
      this.hideBanner();
    }

    this.translateToLanguage();
    this.translateService.onLangChange.subscribe((lang) => {
      this.translateToLanguage();
    }, this);
  }

  translateToLanguage() {
    if (this.translateService.currentLang == 'en') {
      this.headerText = 'Cookie settings';
      this.cookieDescriptionText =
        'We use cookies to provide you with the best possible experience. They also allow us to analyze user behavior in order to constantly improve the website for you.';
      this.acceptText = 'Accept All';
      this.acceptSelectionText = 'Accept Selection';
      this.rejectText = 'Reject All';
      this.necessaryCheckboxText = 'Necessary';
      this.analyticsCheckboxText = 'Analytics';
      this.prefrencesCheckboxText = 'Preferences';
      this.marketingCheckboxText = 'Marketing';
    } else {
      this.headerText = 'Cookie Einstellungen';
      this.cookieDescriptionText =
        'Wir nutzen cookies um die angenehmste Erfahrung für unsere Nutzer zu ermöglichen. Sie erlauben uns außerdem Benutzerverhalten zu analysieren, um Anzeigen und die Website für unsere Nutzer zu optimieren.';
      this.acceptText = 'Alle Akzeptieren';
      this.acceptSelectionText = 'Auswahl akzeptieren';
      this.rejectText = 'Alle ablehnen';
      this.necessaryCheckboxText = 'Notwending';
      this.analyticsCheckboxText = 'Analytisch';
      this.prefrencesCheckboxText = 'Präferenzen';
      this.marketingCheckboxText = 'Marketing';
    }
  }

  acceptSomeClicked() {
    this.setConsent({
      necessary: true,
      analytics: this.isAnalyticsChecked,
      preferences: this.isPreferencesChecked,
      marketing: this.isMarketingChecked,
    });
    this.hideBanner();
  }

  rejectClicked() {
    this.setConsent({
      necessary: false,
      analytics: false,
      preferences: false,
      marketing: false,
    });
    this.hideBanner();
  }

  acceptClicked() {
    this.setConsent({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true,
    });
    this.hideBanner();
  }

  setConsent(consent) {
    const consentMode = {
      functionality_storage: consent.necessary ? 'granted' : 'denied',
      security_storage: consent.necessary ? 'granted' : 'denied',
      ad_storage: consent.marketing ? 'granted' : 'denied',
      ad_user_data: consent.marketing ? 'granted' : 'denied',
      ad_personalization: consent.marketing ? 'granted' : 'denied',
      ad_personalization_signals: consent.marketing ? 'granted' : 'denied',
      analytics_storage: consent.analytics ? 'granted' : 'denied',
      personalization: consent.preferences ? 'granted' : 'denied',
      personalization_storage: consent.preferences ? 'granted' : 'denied',
    };
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
    localStorage.setItem('consentDate', new Date().toISOString());
    location.reload();
  }

  hideBanner() {
    this.cookieBanner.nativeElement.style.display = 'none';
  }
}
