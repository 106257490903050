import { Component, Input } from '@angular/core';
import { SocialMediaType } from '../../models/social-media-type.model';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss'],
})
export class SocialMediaComponent {
  @Input() content: SocialMediaType;
  @Input() backgroundColor: string;
}
