<div class="d-flex flex-column align-items-center p-5" [style.backgroundColor]="backgroundColor">
  <div class="container">
    <div class="inner">
      <h2>{{ content.title }}</h2>
      <h3>{{ content.subtitle }}</h3>
      <p class="mt-4">{{ content.description }}</p>
      <form class="mt-5" [formGroup]="contactFormGroup">
        <div class="row">
          <div class="col-12 col-md-2">
            <div class="form-group">
              <label class="input-label" for="salutation">{{ 'contact.salutation' | translate }}*</label>
              <input
                type="text"
                class="form-control form-text-color"
                id="salutation"
                formControlName="salutation"
                [ngClass]="{
                  'is-invalid': contactFormGroup.get('salutation').invalid && contactFormGroup.get('salutation').touched
                }"
              />
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block': contactFormGroup.get('salutation').invalid && contactFormGroup.get('salutation').touched
                }"
              >
                {{ 'contact.mandatory' | translate }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-5">
            <div class="form-group">
              <label class="input-label" for="prename">{{ 'contact.prename' | translate }}*</label>
              <input
                type="text"
                class="form-control form-text-color"
                id="prename"
                formControlName="prename"
                [ngClass]="{
                  'is-invalid': contactFormGroup.get('prename').invalid && contactFormGroup.get('prename').touched
                }"
              />
              <div
                class="invalid-feedback"
                [ngClass]="{
                  'd-block': contactFormGroup.get('prename').invalid && contactFormGroup.get('prename').touched
                }"
              >
                {{ 'contact.mandatory' | translate }}
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label class="input-label" for="name">{{ 'contact.name' | translate }}*</label>
              <input
                type="text"
                class="form-control form-text-color"
                id="name"
                formControlName="name"
                [ngClass]="{
                  'is-invalid': contactFormGroup.get('name').invalid && contactFormGroup.get('name').touched
                }"
              />
              <div
                class="invalid-feedback"
                [ngClass]="{ 'd-block': contactFormGroup.get('name').invalid && contactFormGroup.get('name').touched }"
              >
                {{ 'contact.mandatory' | translate }}
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="input-label" for="email">{{ 'contact.email' | translate }}*</label>
            <input
              type="email"
              class="form-control form-text-color"
              id="email"
              formControlName="email"
              [ngClass]="{
                'is-invalid': contactFormGroup.get('email').invalid && contactFormGroup.get('email').touched
              }"
            />
            <div
              class="invalid-feedback"
              [ngClass]="{ 'd-block': contactFormGroup.get('email').invalid && contactFormGroup.get('email').touched }"
            >
              {{ 'contact.mandatory' | translate }}
            </div>
          </div>
          <div class="form-group">
            <label class="input-label" for="company">{{ 'contact.company' | translate }}</label>
            <input type="text" class="form-control form-text-color" id="company" formControlName="company" />
          </div>
          <div class="form-group">
            <label class="input-label" for="street">{{ 'contact.street' | translate }}</label>
            <input type="text" class="form-control form-text-color" id="street" formControlName="street" />
          </div>
          <div class="col-12 col-md-3">
            <div class="form-group">
              <label class="input-label" for="post-code">{{ 'contact.post_code' | translate }}</label>
              <input type="text" class="form-control form-text-color" id="post-code" formControlName="postcode" />
            </div>
          </div>
          <div class="col-12 col-md-9">
            <div class="form-group">
              <label class="input-label" for="city">{{ 'contact.city' | translate }}</label>
              <input type="text" class="form-control form-text-color" id="city" formControlName="city" />
            </div>
          </div>
          <div class="form-group">
            <label class="input-label" for="message">{{ 'contact.message' | translate }}*</label>
            <textarea
              type="text"
              class="form-control form-text-color"
              id="message"
              rows="8"
              formControlName="message"
              [ngClass]="{
                'is-invalid': contactFormGroup.get('message').invalid && contactFormGroup.get('message').touched
              }"
            ></textarea>
            <div
              class="invalid-feedback"
              [ngClass]="{
                'd-block': contactFormGroup.get('message').invalid && contactFormGroup.get('message').touched
              }"
            >
              {{ 'contact.mandatory' | translate }}
            </div>
          </div>
          <div class="d-flex justify-content-center mb-3">
            <button class="btn btn-send" [disabled]="!contactFormGroup.valid" (click)="sendMessage()">
              {{ 'contact.send' | translate }}
            </button>
          </div>
        </div>
      </form>
      <h3 *ngIf="success">{{ 'contact.success' | translate }}</h3>
      <h3 *ngIf="error">{{ 'contact.error' | translate }}</h3>
    </div>
  </div>
</div>
