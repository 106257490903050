import { Component, Input, OnInit } from '@angular/core';
import { TextType } from '../../models/text-type.model';
@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class TextComponent implements OnInit {
  @Input() content: TextType;
  @Input() backgroundColor: string;
  @Input() textColor: string;

  ngOnInit() {
    // The splitTexts array only exists so we can have a better overview in the design json files. Otherwise we have extremely long strings without line breaks.
    // Valid json files can't have a string split into multiple lines
    if (this.content.splitTexts && this.content.addedSplitTexts !== true) {
      this.content.addedSplitTexts = true;
      this.content.splitTexts.forEach((partOfTexts) => {
        this.content.text += partOfTexts;
      });
    }
  }
}
