<!-- Footer -->
<section id="footer" class="custom-footer">
  <div class="bg-primary color-white">
    <div class="container top-footer">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6">
          <img src="../../assets/images/logo-white.svg" alt="Logo von Localino" loading="lazy" />

          <p class="brand-container">
            <span class="brand-header">{{ 'footer.brand' | translate }}</span
            ><br />
            <span class="brand-text">Heuel & Löher GmbH & Co. KG</span>
          </p>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 d-flex align-items-end justify-content-end mt-3 mt-md-0">
          <ul class="footer-menu">
            <li>
              <a routerLink="{{ getImprintLink() }}">{{ 'menu.imprint' | translate }}</a>
            </li>
            <li>
              <a routerLink="{{ getImprintLink() }}">{{ 'menu.privacy' | translate }}</a>
            </li>
            <li>
              <a routerLink="{{ getContactLink() }}">{{ 'menu.contact' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="bg-white">
    <div class="container bottom-footer">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 copyright-text">
          &copy; 2024 Localino - Solutions for localization and digitalization.<br />
          All rights reserved. ISO 9001:2015 Certified.
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 d-flex justify-content-end align-items-end mt-3 mt-md-0">
          <img
            class="made-in-germany-logo"
            src="../../assets/images/made-in-germany.svg"
            alt="Made in Germany"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  </div>
</section>
