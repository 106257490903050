import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { CardData } from '../../interfaces/cardData.interface';
import { TileType } from '../../models/tile.model';

@Component({
  selector: 'app-dropdown-tile-wrapper',
  templateUrl: './dropdown-tile-wrapper.component.html',
  styleUrls: ['./dropdown-tile-wrapper.component.scss'],
})
export class DropdownTileWrapperComponent implements OnInit {
  @Input() content: TileType[] = [];
  @Input() opacityMultiplier: number = 0.1;
  expandedTileIndex: number;
  mobile: boolean = false;

  constructor() {}

  ngOnInit() {
    if (window.screen.width <= 576) {
      this.mobile = true;
    }

    if (this.content.length === 0 || this.content[0].titleNumber === null) {
      return;
    }

    this.expandedTileIndex = 0;
  }

  getSmallerDimension(element: HTMLElement): number {
    if (!element) return 0; // Ensure the element is defined
    const { offsetHeight, offsetWidth } = element;
    return Math.min(offsetHeight, offsetWidth);
  }

  getBackgroundColorFromIndex(index: number): string {
    if (!this.content || !this.content[0] || !this.content[0].backgroundColor) {
      return '#FFFFFF';
    }

    let firstBackgroundColor: string = this.content[0].backgroundColor.slice(0, 7);
    let opacity: number = 1 - index * this.opacityMultiplier;
    let alpha: string = this.getAplhaHexFromOpacity(opacity);
    return firstBackgroundColor + alpha;
  }

  getAplhaHexFromOpacity(opacity: number) {
    return Math.round(opacity * 255).toString(16);
  }

  expandTile(index: number) {
    if (this.expandedTileIndex === index) {
      this.expandedTileIndex = null;
      return;
    }
    this.expandedTileIndex = index;
  }
}
