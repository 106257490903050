import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './pages/main/main.component';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    pathMatch: 'full',
  },
  {
    path: ':id',
    component: MainComponent,
    pathMatch: 'full',
  },
  {
    path: ':id/:id2',
    component: MainComponent,
    pathMatch: 'full',
  },
  {
    path: ':id/:id2/:id3',
    component: MainComponent,
    pathMatch: 'full',
  },
  {
    path: ':id/:id2/:id3/:id4',
    component: MainComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
