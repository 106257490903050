import { Component } from '@angular/core';
import { PageService } from '../../modules/core/services/page.service';
import { Page } from '../../modules/core/models/page.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TypesEnum } from '../../modules/core/enums/types.enum';
import { SEOService } from '@lenne.tech/ng-base';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  page: Page;
  Types = TypesEnum;

  constructor(
    private pageService: PageService,
    private route: ActivatedRoute,
    private router: Router,
    private seoService: SEOService
  ) {
    this.route.params.subscribe((params) => {
      this.updatePageByRoute(this.prepareIds(params));
    });
  }

  prepareIds(params: Params): string {
    let route = '';

    if (params.id) {
      route = params.id;

      if (params.id2) {
        route = params.id + '/' + params.id2;

        if (params.id3) {
          route = params.id + '/' + params.id2 + '/' + params.id3;

          if (params.id4) {
            route = params.id + '/' + params.id2 + '/' + params.id3 + '/' + params.id4;
          }
        }
      }
    } else {
      route = '/';
    }

    return route;
  }

  log(content: any) {
    console.log(content);
  }

  updatePageByRoute(route: string) {
    let lastRoute = '';
    this.pageService.pagesAsObservable.subscribe(async (pages) => {
      if (route) {
        lastRoute = route;
      }

      this.page = pages.find((page) => page.route === lastRoute);
      if (!this.page) {
        await this.router.navigateByUrl('/');
      } else {
        this.seoService.initPageForSEO({
          title: this.page.seo.title,
          description: this.page.seo.description,
          keywords: this.page.seo.keywords?.join(','),
        });
      }
    });
  }
}
