import { ElementRef, HostBinding, Component, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-smooth-expander',
  templateUrl: './smooth-expander.component.html',
  styleUrls: ['./smooth-expander.component.scss'],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [style({ height: '{{startHeight}}px', opacity: 0 }), animate('.5s ease')], {
        params: { startHeight: 0 },
      }),
    ]),
  ],
})
export class SmoothExpanderComponent implements OnChanges {
  @Input()
  trigger: any;

  @Output() animationComplete = new EventEmitter<void>();

  startHeight: number;

  @HostBinding('@grow') grow: any;

  constructor(private element: ElementRef) {}

  ngOnChanges() {
    this.startHeight = this.element.nativeElement.clientHeight;

    this.grow = {
      value: this.trigger,
      params: { startHeight: this.startHeight },
    };
  }

  onGrowAnimationDone() {
    this.animationComplete.emit();
  }
}
