<div #container class="position-relative content-container">
  <div
    #slider
    class="slider-card d-flex align-items-center justify-content-center"
    [ngStyle]="{ 'height.px': container.offsetWidth / 10, 'width.px': container.offsetWidth / 2 }"
  >
    <div class="splide">
      <div class="splide__track">
        <div class="splide__list">
          <div
            class="splide__slide d-flex flex-column justify-content-center align-items-center"
            *ngFor="let slide of content.referenceImages; let i = index"
          >
            <img
              [src]="slide.src"
              [alt]="slide.alt"
              loading="lazy"
              [ngStyle]="{ 'height.px': slider.offsetHeight - 10 }"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="stripe-bottom" [style.backgroundColor]="content.lowerColor || 'white'"></div>
</div>
