<app-hero-image [hero]="page.hero"></app-hero-image>
<app-side-contact></app-side-contact>
<div *ngFor="let content of page.content" [ngSwitch]="content.type">
  <app-text
    *ngSwitchCase="Types.TEXT"
    [content]="content.textType"
    [backgroundColor]="content.backgroundColor"
    [textColor]="content.textColor"
  ></app-text>
  <app-spacer *ngSwitchCase="Types.SPACER" [backgroundColor]="content.backgroundColor" [height]="content.height">
  </app-spacer>
  <app-filler
    *ngSwitchCase="Types.FILLER"
    [backgroundColor]="content.backgroundColor"
    [heightMultiplicator]="content.heightMultiplicator"
  ></app-filler>
  <app-page-tile *ngSwitchCase="Types.PAGE_TILE" [content]="content.pageTileType" [cols]="content.cols"></app-page-tile>
  <app-tile-wrapper *ngSwitchCase="Types.TILES" [content]="content.tiles"></app-tile-wrapper>
  <app-dropdown-tile-wrapper
    *ngSwitchCase="Types.DROPDOWN_TILES"
    [content]="content.tiles"
    [opacityMultiplier]="content.opacityMultiplier"
  ></app-dropdown-tile-wrapper>
  <app-panel-wrapper *ngSwitchCase="Types.PANELS" [content]="content.panels"></app-panel-wrapper>
  <app-text-wrapper *ngSwitchCase="Types.TEXTS" [content]="content.texts"></app-text-wrapper>
  <app-find-us
    *ngSwitchCase="Types.FIND_US"
    [content]="content.findUsType"
    [backgroundColor]="content.backgroundColor"
    [textColor]="content.textColor"
  ></app-find-us>
  <app-reference-slider
    *ngSwitchCase="Types.REFERENCE_SLIDER"
    [content]="content.referenceSliderType"
  ></app-reference-slider>
  <app-video *ngSwitchCase="Types.VIDEO" [content]="content.videoType"></app-video>
  <app-image *ngSwitchCase="Types.IMAGE" [content]="content.imageType"></app-image>
  <app-contact
    *ngSwitchCase="Types.CONTACT"
    [content]="content.contactType"
    [backgroundColor]="content.backgroundColor"
  ></app-contact>
  <app-contact-for-questions
    *ngSwitchCase="Types.CONTACT_FOR_QUESTIONS"
    [content]="content.contactForQuestionsType"
  ></app-contact-for-questions>
  <app-social-media
    *ngSwitchCase="Types.SOCIAL_MEDIA"
    [content]="content.socialMediaType"
    [backgroundColor]="content.backgroundColor"
  ></app-social-media>
  <app-slider
    *ngSwitchCase="Types.SLIDER"
    [content]="content.sliderType"
    [backgroundColor]="content.backgroundColor"
  ></app-slider>
</div>
