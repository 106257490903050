<div class="social-media-wrapper" [style.backgroundColor]="backgroundColor">
  <div class="container">
    <div class="text-center mb-5">
      <h2 [style.color]="content?.title?.color">{{ content.title.text }}</h2>
      <h3 [style.color]="content?.subtitle?.color">{{ content.subtitle.text }}</h3>
    </div>
    <div #iconContainer class="d-flex flex-row justify-content-center align-items-center">
      <ng-container *ngFor="let socialItem of content.items">
        <a class="link" [href]="socialItem.link">
          <img
            [src]="socialItem.image.src"
            [alt]="socialItem.image?.alt"
            [ngStyle]="{ 'width.px': iconContainer.offsetWidth / 15, 'height.px': iconContainer.offsetWidth / 15 }"
          />
        </a>
      </ng-container>
    </div>
  </div>
</div>
