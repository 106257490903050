import { Component, Input } from '@angular/core';
import { ImageType } from '../../models/image-type.model';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent {
  @Input() content: ImageType;
}
