<div class="tile-wrapper">
  <ng-container *ngFor="let tile of content">
    <div #square (window:resize)="(0)" [ngStyle]="{ 'height.px': square.offsetWidth / 1.3 }" class="host">
      <app-tile
        [backgroundColor]="tile?.backgroundColor"
        [backgroundImage]="tile?.backgroundImage"
        [scaleDown]="tile?.scaleDownImage"
        [isFlippable]="tile?.isFlippable"
        [topPercent]="tile?.topPercent"
        [leftPercent]="tile?.leftPercent"
        [isPageTile]="tile?.isPageTile"
      >
        <div class="tile-text-container" [ngStyle]="{ 'text-align': tile?.textAlignment }">
          <img
            *ngIf="tile?.image"
            [src]="tile?.image?.src"
            [alt]="tile?.image?.alt"
            [title]="tile?.image?.alt"
            loading="lazy"
            class="mb-4"
            [ngStyle]="{
              'max-height.px': square.offsetWidth / tile.imageMaxFractionOfHeight,
              'max-width.px': square.offsetWidth / 2
            }"
          />
          <div *ngIf="tile.headerLayout === 'h3p'">
            <h3 class="title" [style.Color]="tile?.title?.color">{{ tile?.title?.text }}</h3>
            <p class="subtitle" [style.Color]="tile?.subtitle?.color">{{ tile?.subtitle?.text }}</p>
          </div>
          <div *ngIf="tile.headerLayout === 'ph2'">
            <p class="title" [style.Color]="tile?.title?.color">{{ tile?.title?.text }}</p>
            <h2 class="subtitle" [style.Color]="tile?.subtitle?.color">{{ tile?.subtitle?.text }}</h2>
          </div>
          <div *ngIf="tile.headerLayout === 'h2h2' || !tile.headerLayout">
            <h2 class="title" [style.Color]="tile?.title?.color">{{ tile?.title?.text }}</h2>
            <h2 class="subtitle" [style.Color]="tile?.subtitle?.color">{{ tile?.subtitle?.text }}</h2>
          </div>

          <p class="paragraph pre-line" [style.Color]="tile?.description?.color">
            {{ tile?.description?.text }}
          </p>
          <a [routerLink]="tile?.button?.link">
            <button
              *ngIf="tile?.button"
              class="btn"
              [ngClass]="tile?.button?.classes ? tile.button.classes : 'btn-accent'"
            >
              {{ tile?.button?.text }}
            </button>
          </a>
        </div>
      </app-tile>
    </div>
  </ng-container>
</div>
