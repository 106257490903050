<div
  #square
  class="tile"
  (mouseenter)="isFlippable === true && cardHovered()"
  (mouseleave)="isFlippable === true && cardHovered()"
>
  <!--We need isFlippable to make the background transparent, because it has an inherit background color that makes the flip not function in the Safari Browser-->
  <div
    class="card rounded-0 border-0"
    [@cardFlip]="data.state"
    [ngStyle]="
      (isFlippable !== true && { backgroundColor: backgroundColor }) ||
      (isFlippable === true && { backgroundColor: 'transparent' })
    "
    [style.height.px]="square.offsetWidth / 1.3"
  >
    <div class="face back rounded-0 border-0" [ngStyle]="isFlippable !== true && { transform: 'rotateY(0deg)' }">
      <div *ngIf="isPageTile === true" class="img-overlay"></div>
      <img
        *ngIf="backgroundImage"
        [src]="backgroundImage?.src"
        [alt]="backgroundImage?.alt"
        [title]="backgroundImage?.alt"
        [ngStyle]="{ 'height.px': square.offsetWidth / 1.3 }"
        [ngClass]="{ 'scale-down': scaleDown }"
        loading="lazy"
      />
    </div>

    <div
      class="face front rounded-0 border-0"
      [ngStyle]="
        (isFlippable !== true && { transform: 'rotateY(0deg)' }) ||
        (isFlippable === true && { backgroundColor: backgroundColor })
      "
    >
      <div
        class="tile-inside"
        [ngStyle]="{
          top: mobile === false ? topPercent + '%' : '50%',
          left: mobile === false ? leftPercent + '%' : '50%',
          position: position,
          transform: transformContentToCenter === true ? 'translateX(-50%) translateY(-50%)' : 'none'
        }"
      >
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
