<div #cookieBanner class="cookie-consent-banner">
  <h3>{{ headerText }}</h3>
  <p>{{ cookieDescriptionText }}</p>
  <button class="cookie-consent-button btn-success" (click)="acceptClicked()">{{ acceptText }}</button>
  <button class="cookie-consent-button btn-outline" (click)="acceptSomeClicked()">{{ acceptSelectionText }}</button>
  <button class="cookie-consent-button btn-grayscale" (click)="rejectClicked()">{{ rejectText }}</button>
  <div class="cookie-consent-options">
    <label><input type="checkbox" value="Necessary" checked disabled />{{ necessaryCheckboxText }}</label>
    <label
      ><input type="checkbox" value="Analytics" [(ngModel)]="isAnalyticsChecked" />{{ analyticsCheckboxText }}</label
    >
    <label
      ><input type="checkbox" value="Preferences" [(ngModel)]="isPreferencesChecked" />{{
        prefrencesCheckboxText
      }}</label
    >
    <label
      ><input type="checkbox" value="Marketing" [(ngModel)]="isMarketingChecked" />{{ marketingCheckboxText }}</label
    >
  </div>
</div>
