import { Component, Input, OnInit } from '@angular/core';
import { Image } from '../../interfaces/image.interface';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CardData } from '../../interfaces/cardData.interface';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  animations: [
    trigger('cardFlip', [
      state(
        'default',
        style({
          transform: 'none',
        })
      ),
      state(
        'flipped',
        style({
          transform: 'rotateY(180deg)',
        })
      ),
      transition('default => flipped', [animate('400ms')]),
      transition('flipped => default', [animate('400ms')]),
    ]),
  ],
})
export class TileComponent implements OnInit {
  @Input() backgroundColor = '#ffffff';
  @Input() backgroundImage: Image;
  @Input() subtitle: string;
  @Input() scaleDown: boolean;
  @Input() isFlippable: boolean = false;
  @Input() isPageTile: boolean = false;
  @Input() topPercent: number = 50;
  @Input() leftPercent: number = 50;
  @Input() position: string = 'absolute';
  @Input() transformContentToCenter: boolean = true;

  mobile: boolean = false;

  data: CardData = {
    imageId: 'pDGNBK9A0sk',
    state: 'default',
  };

  ngOnInit() {
    if (window.screen.width <= 991) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  cardHovered() {
    if (this.data.state === 'default') {
      this.data.state = 'flipped';
    } else {
      this.data.state = 'default';
    }
  }
}
