import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-side-contact',
  templateUrl: './side-contact.component.html',
  styleUrls: ['./side-contact.component.scss'],
})
export class SideContactComponent {
  constructor(private translateService: TranslateService) {}

  getContactLink(): string {
    if (this.translateService.currentLang === 'de') {
      return '/kontakt';
    }

    return '/contact';
  }
}
