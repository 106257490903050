<div #container class="iframe-container">
  <iframe
    [src]="sanitize(content.url)"
    [ngStyle]="{ 'height.px': container.offsetWidth * 0.33 }"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    loading="lazy"
    allowfullscreen
  ></iframe>
</div>
