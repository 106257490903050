import { SEO } from './seo.model';
import { Helper, Standard } from '@lenne.tech/ng-base/shared';
import { CoreType } from './core-type.model';
import { Hero } from './hero.model';

export class Page extends Standard {
  id: string = undefined;
  name: string = undefined;
  title: string = undefined;
  mainMenu = false;
  route: string = undefined;
  link: string = undefined;
  seo: SEO = undefined;
  hero: Hero = undefined;
  content: CoreType[] = undefined;
  childrenIds: string[] = undefined;

  public map(data: Partial<this> | { [key: string]: any }): this {
    Helper.map(data, this);
    this.content = Helper.maps(data.content, CoreType);
    return this;
  }
}
