import { Component, Input } from '@angular/core';
import { ContactType } from '../../models/contact.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MailService } from '../../services/mail.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  @Input() content: ContactType;
  @Input() backgroundColor: string;

  error = false;
  success = false;
  contactFormGroup: UntypedFormGroup;

  constructor(private mailService: MailService) {
    this.initForm();
  }

  initForm() {
    this.contactFormGroup = new UntypedFormGroup({
      salutation: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      prename: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      city: new UntypedFormControl('', null),
      postcode: new UntypedFormControl('', null),
      street: new UntypedFormControl('', null),
      message: new UntypedFormControl('', Validators.required),
      company: new UntypedFormControl('', null),
    });
  }

  sendMessage() {
    this.mailService.sendMail(this.contactFormGroup.value).subscribe({
      next: (value) => {
        this.error = value === false;
        this.success = value === true;
        setTimeout(() => {
          this.error = false;
          this.success = false;
        }, 3000);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
