<div #heroRegion class="hero-image-container" [style.backgroundColor]="hero.backgroundColor">
  <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators" *ngIf="hero.images.length > 1">
      <button
        *ngFor="let item of hero.images; let i = index"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        [attr.data-bs-slide-to]="i"
        [ngClass]="{ active: i === 0 }"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
    </div>

    <div class="carousel-inner">
      <div class="carousel-item" *ngFor="let item of hero.images; let i = index" [ngClass]="{ active: i === 0 }">
        <img
          [src]="item.image.src"
          class="d-block w-100 carousel-image"
          [alt]="item.image.alt"
          [title]="item.image.alt"
          loading="lazy"
        />
        <div #heroTextContainer *ngIf="hero.showText" class="carousel-caption d-none d-md-block">
          <button
            *ngIf="hero.images.length > 1"
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <i class="bi bi-chevron-left"></i>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            *ngIf="hero.images.length > 1"
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <i class="bi bi-chevron-right"></i>
            <span class="visually-hidden">Next</span>
          </button>

          <p class="title">{{ item.title }}</p>
          <h1 class="subtitle">{{ item.subtitle }}</h1>
        </div>
      </div>
    </div>
  </div>
</div>
