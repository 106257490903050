import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Image } from '../../interfaces/image.interface';
import { SliderTypeModel } from '../../models/slider-type.model';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent {
  @Input() content: SliderTypeModel;
  @Input() backgroundColor = '#ffffff';
}
