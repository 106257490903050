import { Component, Input, OnInit } from '@angular/core';
import { PanelType } from '../../models/panel.model';

@Component({
  selector: 'app-panel-wrapper',
  templateUrl: './panel-wrapper.component.html',
  styleUrls: ['./panel-wrapper.component.scss'],
})
export class PanelWrapperComponent {
  @Input() content: PanelType[] = [];
  constructor() {}
}
