import { Component, Input, ViewChild } from '@angular/core';
import { Hero } from '../../models/hero.model';

@Component({
  selector: 'app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrls: ['./hero-image.component.scss'],
})
export class HeroImageComponent {
  @Input() hero: Hero;
  @ViewChild('heroTextContainer') heroTextDiv: HTMLDivElement;
}
